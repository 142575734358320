import $ from "jquery";
import ScrollMagic from "scrollmagic";
import TweenMax from "gsap/TweenMax";

import Scroll from "../../02_molecules/Scroll/Scroll";
import * as texthelpers from "../../00_helpers/texthelper";
import Video from "../../02_molecules/Video/Video";

import * as AboutScenes from '../../00_helpers/aboutscenes';
import SceneFactory from '../../00_helpers/scenefactory';


export default class About {

    constructor() {
        this.TLscenes = [];
        this.SMscenes = [];
    }

    init() {

        this.SceneFactory = new SceneFactory();

        new Scroll();

        this.controller = new ScrollMagic.Controller({
            refreshInterval: 0
        });

        this.createScenes();

        texthelpers.spanWrapAllWords(document.querySelectorAll('.js-block-text'));

        $('.js-play-video').on('click', (e) => {

            e.preventDefault();
            this.createVideo(e.currentTarget.dataset.video);
            this.initVideo();

        });

        (function(that) {

            $(window).on('resize', function() {

                clearTimeout(that.resizeTimer);
                that.resizeTimer = setTimeout(function() { that.refreshScenes(); }, 200);

            });

        })(this);

    }

    refreshScenes() {

        this.SMscenes.forEach((scene, index) => {

            scene.refresh();

        });

    }

    calculateClipPath() {
        return "polygon(" + (-0.364 * window.innerHeight) + "px 0%, " + window.innerWidth + "px 0%, " + (0.364 * window.innerHeight + window.innerWidth) + "px 100%, 0% 100%)";
    }


    createScenes() {

        // Intro
        this.TLscenes.push({TimeLineScene: null, element: '.js-scene-intro'});

        //scenes
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene0(), element: '.js-scene-AOTY'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene1(), element: '.js-scene-disruption'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene2(), element: '.js-scene-quote'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene3(), element: '.js-scene-creativity'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene4(), element: '.js-scene-culture'});
        //this.TLscenes.push({TimeLineScene: AboutScenes.TLscene5(), element: '.js-scene-disruptionlive'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene6(), element: '.js-scene-quote-2'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene7(), element: '.js-scene-backslash'});
        //this.TLscenes.push({TimeLineScene: AboutScenes.TLscene8(), element: '.js-scene-tbwa'});
        //this.TLscenes.push({TimeLineScene: AboutScenes.TLscene9(), element: '.js-scene-facts'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene10(), element: '.js-scene-clients'});
        this.TLscenes.push({TimeLineScene: AboutScenes.TLscene11(), element: '.js-scene-contact'});


        /*  Create scenes and add to controller/Scroll  */
        var that = this;
        this.TLscenes.forEach((scene, index) => {

            if (scene.TimeLineScene !== null) {

                that.SMscenes.push(this.SceneFactory.createScene({
                    triggerElement: scene.element,
                    setTween: scene.TimeLineScene,
                    addTo: this.controller
                }));

            }

            // background visuals
            const currentScene = $(scene.element);

            // check if scene exists
            if(currentScene.length) {

                const visual = currentScene.attr('data-visual');
                const s = new ScrollMagic.Scene({
                    duration: '200%',
                    triggerHook: 'onEnter'
                }).addTo(this.controller);

                s.triggerElement(currentScene[0]);

                s.on('enter', (e) => {
                    $('.' + visual).show();
                });

                s.on('leave', (e) => {
                    $('.' + visual).hide();
                });

                that.SMscenes.push(s);

            }

        });

    }


    createVideo(video) {
        $('.js-video-overlay').html('<video class="js-video video-js" controls="" preload="auto" data-video="' + video + '" ></video>');
    }


    initVideo() {


        this.video = new Video({
            element: '.js-video',
            parentElement: '.js-video-overlay',
            onReady: () => {
                $('.js-video-overlay').addClass('is-visible');

                TweenMax.to('.js-video-overlay', .5, {
                    opacity: 1
                });
            },
            onClose: () => {
                TweenMax.to('.js-video-overlay', .5, {
                    opacity: 0,
                    onComplete: () => {
                        this.video.player.dispose();
                        $('.js-video-overlay').removeClass('is-visible');
                    }
                });
            }

        });


    }

}