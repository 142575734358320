let SYS = {
	DEBUG: true,
	languages: { nl: "nl", fr: "fr", en: "fr" },
	shareData: {
		url: "http://tbwagroup.be/",
		title: "Share Module Title",
		descr: "This is a Share-Module Demo.",
		hashtags: "Dansen,Feest"
	},
	api: {
		form_url: "/path/to/api/"
	},
	localStorageKey: "storage",
	localstorageStore: true,
	cookieStorageKey: "cookie-consent-level",
};

export default SYS;
