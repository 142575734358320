import videojs from 'video.js';

export default class Video {

    constructor(options = {}) {
        this.options = Object.assign({
            element: ".js-video",
            parentElement: ".js-video-parant",
            onOpen: (e) => {
            },
            onClose: (e) => {
            },
            onReady: (e) => {
            },

        }, options);


        let _this = this;

        this.el = document.querySelector(this.options.element);
        this.parentEl = document.querySelector(this.options.parentElement);
        this.triggerEl = document.querySelector(this.options.trigger);





        this.player = videojs(this.el,{
            inactivityTimeout: 500
        });
        this.player.src({
            src: this.el.getAttribute("data-video"),
            type: 'video/mp4'

        }   
            );

        // Ready event
        this.player.ready(()=>{
            _this.options.onReady();
            this.player.play();
        });


        this.player.on('ended', () => {
            this.options.onClose();
        });




        let VjsButton = videojs.getComponent("CloseButton");


        // Extend default
        var Closebutton = videojs.extend(VjsButton, {
          constructor: function() {
            VjsButton.apply(this, arguments);
            this.controlText("Close");
          },

          handleClick: function() {
            _this.remove();
          }
        });


        videojs.registerComponent('Closebutton', Closebutton);
        this.player.getChild('controlBar').addChild('Closebutton', {}, 15);

    }

    remove(){
        this.options.onClose();
    }
}
